<template>
  <div
    v-if="topBarContent && topBarContent.length > 0"
    class="top-bar__container"
  >
    <div class="top-bar__message">
      <div class="glide" :id="topBarGlideId">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides">
            <li
              class="glide__slide"
              v-for="(topBarMessage, index) in topBarContent"
              :key="`topBarMessage-${index}`"
            >
              <div class="top-bar__message__details">
                <span
                  v-if="topBarMessage.text"
                  class="top-bar__message__details__text"
                >
                  {{ topBarMessage.text }}
                </span>
                <SfLink
                  v-if="topBarMessage.link"
                  :link="topBarMessage.link['link-url']"
                  :target="topBarMessage.link['link-target']"
                  class="top-bar__message__details__link"
                >
                  {{ topBarMessage.link['link-label'] }}
                </SfLink>
              </div>
            </li>
          </ul>
        </div>
        <div class="glide__arrows" data-glide-el="controls">
          <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
            <ChevronWhiteLeft />
          </button>
          <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
            <ChevronWhiteRight />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  useFetch,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';
import { useContent } from '@gemini-vsf/composables';
import { contentGetters } from '~/composables';
import { SfLink } from '@storefront-ui/vue';
import Glide, {
  Controls,
  Autoplay,
} from '@glidejs/glide/dist/glide.modular.esm';
import {
  ChevronWhiteRight,
  ChevronWhiteLeft,
} from '~/components/General/Icons';

export default defineComponent({
  name: 'TopBar',
  components: { SfLink, ChevronWhiteRight, ChevronWhiteLeft },
  props: {},
  setup() {
    const {
      app: {
        i18n: {
          localeProperties: { localeValue },
        },
      },
    } = useContext();
    const { blocks, loadBlocks } = useContent(`TopBar-${localeValue}`);
    const topBarSelector = `top-bar-${localeValue}`;
    const topBarContent = ref([]);
    const topBarGlideId = ref('top-bar-glide');

    const getSlide = (s) => {
      const data = {};
      if (s?.active) {
        if (s.text) {
          data.text = s.text;
        }
        if (
          s.link &&
          s.link['link-label'] &&
          s.link['link-url'] &&
          s.link['link-target']
        ) {
          data.link.label = s.link['link-label'];
          data.link.url = s.link['link-url'];
          data.link.target = s.link['link-target'];
        }
      }
      return data;
    };

    useFetch(async () => {
      try {
        await loadBlocks({ identifiers: [topBarSelector] });
        const blockContent = contentGetters.getCmsBlockContent(blocks.value);
        if (Object.keys(blockContent).length > 0) {
          const topBarContentData = blockContent[topBarSelector];
          if (topBarContentData?.active && topBarContentData.data?.length > 0) {
            topBarContent.value = topBarContentData.data
              .map((s) => getSlide(s))
              .filter((s) => s !== {});
          }
        }
      } catch {
        console.warn('Could not get topbar content');
      }
    });

    onMounted(() => {
      let container = document.querySelector(`#${topBarGlideId.value}`);
      if (container) {
        const glideOptions = {
          autoplay: 5000,
          animationDuration: 1500,
        };
        new Glide('.glide', glideOptions).mount({
          Controls,
          Autoplay,
        });
      }
    });

    return {
      topBarGlideId,
      topBarContent,
    };
  },
});
</script>

<style lang="scss">
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.theme';
.top-bar {
  &__container {
    width: 100%;
    height: 1.875rem;
    @include background-black;
    @include flex-center;
    z-index: 5;
    .glide__arrow {
      &--left {
        left: 0;
        border: 0;
      }
      &--right {
        right: 0;
        border: 0;
      }
    }
  }
  &__message {
    width: 100%;
    &__details {
      margin: 0 2rem;
      text-align: center;
      @include flex-center;
      gap: 0.3rem;
      &__text,
      &__link {
        color: var(--c-white);
        --link-color: var(--c-white);
        @include paragraph-s;
      }
    }
  }
  @include from-desktop-min {
    &__container {
      .glide__arrow {
        &--left {
          left: 22.1875rem;
        }
        &--right {
          right: 22.1875rem;
        }
      }
    }
    &__message {
      &__details {
        margin: 0;
      }
    }
  }
}
</style>
